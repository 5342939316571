import { CommissionPayPlanType } from './commission-pay-plan-type';

export class SupplierCompensationReport {
	upfrontPercentage: number;
	paymentRule: string;
	description: string;
	rating: string;
	commodity: string;
	marginSplitRule: string;
	supplier: string;
	marginCapRule: string;
	paymentAmount: string;
	paymentTimeLag: string;
	residualPaymentRule: string;
	upfrontPaymentRule: string;
	upfront50PaymentRule: string;
	quickPayUpfront50PaymentRule: string;
	quickPayUpfrontPaymentRule: string;
	quickPayDescription: string;
	commissionPayPlanTypes: CommissionPayPlanType[];
}
