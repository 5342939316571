import { RateMatrixOptimizedPrice } from './rate-matrix-optimized-price';
import { SupplierMarketMatrixPriceAdjustmentSummaryReport } from './supplier-market-matrix-price-adjustment-summary-report';

export class SupplierMarketMatrixRankingStatsByState {
	public latestStatsReport: RateMatrixOptimizedPrice[];
	public latestStatsReportTotal: number;
	public latestStatsReportFields: {
		effectiveDates: string[];
		terms: number[];
		usages: number[];
		utilities: { name: string; utilityId: string }[];
		rateClasses: string[];
		zones: string[];
		productIds: string[];
	};
	public impactAnalysisReport: SupplierMarketMatrixPriceAdjustmentSummaryReport;
	public avgRank: number;

	constructor(supplierMarketMatrixRankingStatsByState: SupplierMarketMatrixRankingStatsByState) {
		Object.assign(this, supplierMarketMatrixRankingStatsByState);
	}
}
