export class SupplierMarketMatrixImpactAnalysisDetail {
	public numCases: number;
	public averageImpactSize: number;
}

export class SupplierMarketMatrixImpactAnalysisReport {
	public tooLow: SupplierMarketMatrixImpactAnalysisDetail;
	public low: SupplierMarketMatrixImpactAnalysisDetail;
	public high: SupplierMarketMatrixImpactAnalysisDetail;
	public tooHigh: SupplierMarketMatrixImpactAnalysisDetail;
}
