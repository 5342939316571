import { SupplierMarketMatrixPriceAdjustmentSummaryReport } from './supplier-market-matrix-price-adjustment-summary-report';

export class SupplierMarketMatrixRankingStats {
	public latestStatsReportStates: {
		stateLong: string; stateId: string;
		avgAdjustment: number; maxAdjustment: number; minAdjustment: number;
	}[];
	public priceAdjustmentSummaryReport: SupplierMarketMatrixPriceAdjustmentSummaryReport;

	constructor(supplierMarketMatrixRankingStats: SupplierMarketMatrixRankingStats) {
		Object.assign(this, supplierMarketMatrixRankingStats);
	}
}
