import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { ServiceType } from './service-type';

export class PricingAnalyticsUtilityPerformancesChartOptions {
	public chartOptions: any[] = [];

	constructor(serviceType?: ServiceType) {
		this.chartOptions = [{
			label: 'optimalScore',
			options: {
				animationEnabled: true,
				axisX: {
					title: 'Add Date',
					titleFontColor: '#707070',
					titleFontFamily: 'Montserrat',
					titleFontSize: 14,
					labelAngle: 50,
					labelFontFamily: 'Montserrat',
					labelFontSize: 12,
					valueFormatString: 'MMM DD, YYYY',
				},
				axisY: {
					title: 'Avg Optimality Score',
					titleFontColor: '#707070',
					titleFontFamily: 'Montserrat',
					titleFontSize: 14,
					labelFontFamily: 'Montserrat',
					minimum: 0,
					maximum: 1,
					labelFontSize: 12,
				},
				toolTip: {
					contentFormatter: (e: any): any => !e.entries[0].dataPoint.hideTooltip
						? `<span data-color="#6c91a7" style="color: rgb(108, 145, 167);">
						${moment(e.entries[0].dataPoint.x).format('MMM DD, YYYY')}:
						</span>&nbsp;&nbsp;${NumberUtils.round(e.entries[0].dataPoint.y * 100, 2)}%`
						: null,
				},
				data: [{
					color: '#6c91a7',
					name: 'views',
					type: 'area',
					dataPoints: [],
				}],
			},
		},
		{
			label: 'marketShare',
			options: {
				animationEnabled: true,
				axisX: {
					title: 'Add Date',
					titleFontColor: '#707070',
					titleFontFamily: 'Montserrat',
					titleFontSize: 14,
					labelAngle: 50,
					labelFontFamily: 'Montserrat',
					labelFontSize: 12,
					valueFormatString: 'MMM DD, YYYY',
				},
				axisY: {
					title: 'Avg Market Share',
					titleFontColor: '#707070',
					titleFontFamily: 'Montserrat',
					titleFontSize: 14,
					labelFontFamily: 'Montserrat',
					minimum: 0,
					maximum: 1,
					labelFontSize: 12,
				},
				toolTip: {
					contentFormatter: (e: any): any => !e.entries[0].dataPoint.hideTooltip
						? `<span data-color="#009900" style="color: rgb(0, 153, 0);">
						${moment(e.entries[0].dataPoint.x).format('MMM DD, YYYY')}:
						</span>&nbsp;&nbsp;${NumberUtils.round(e.entries[0].dataPoint.y * 100, 2)}%`
						: null,
				},
				data: [{
					color: '#009900',
					name: 'views',
					type: 'area',
					dataPoints: [],
				}],
			},
		},
		{
			label: 'lostMargin',
			options: {
				animationEnabled: true,
				axisX: {
					title: 'Add Date',
					titleFontColor: '#707070',
					titleFontFamily: 'Montserrat',
					titleFontSize: 14,
					labelAngle: 50,
					labelFontFamily: 'Montserrat',
					labelFontSize: 12,
					valueFormatString: 'MMM DD, YYYY',
				},
				toolTip: {
					contentFormatter: (e: any): any => !e.entries[0].dataPoint.hideTooltip
						? `<span data - color="#fc8f3b" style = "color: rgb(252, 143, 59);">
						${moment(e.entries[0].dataPoint.x).format('MMM DD, YYYY')}:
						</span>&nbsp;&nbsp;${e.entries[0].dataPoint.y} ${serviceType
							? serviceType.units2 + '/' + serviceType.units
							: ''
						}`
						: null,
				},
				axisY: {
					title: 'Avg Lost Margin',
					titleFontColor: '#707070',
					titleFontFamily: 'Montserrat',
					titleFontSize: 14,
					labelFontFamily: 'Montserrat',
					minimum: 0,
					labelFontSize: 12,
				},
				data: [{
					color: '#fc8f3b',
					name: 'views',
					type: 'area',
					dataPoints: [],
				}],
			},
		},
		{
			label: 'clusterScore',
			options: {
				animationEnabled: true,
				axisX: {
					title: 'Add Date',
					titleFontColor: '#707070',
					titleFontFamily: 'Montserrat',
					titleFontSize: 14,
					labelAngle: 50,
					labelFontFamily: 'Montserrat',
					labelFontSize: 12,
					valueFormatString: 'MMM DD, YYYY',
				},
				toolTip: {
					contentFormatter: (e: any): any => !e.entries[0].dataPoint.hideTooltip
						? `<span data - color="#27569C" style = "color: rgb(39, 86, 156);">
						${moment(e.entries[0].dataPoint.x).format('MMM DD, YYYY')}:
						</span>&nbsp;&nbsp;${NumberUtils.round(e.entries[0].dataPoint.y, 2)}%`
						: null,
				},
				axisY: {
					title: 'Avg Cluster Score',
					titleFontColor: '#707070',
					titleFontFamily: 'Montserrat',
					titleFontSize: 14,
					labelFontFamily: 'Montserrat',
					minimum: 0,
					labelFontSize: 12,
				},
				data: [{
					color: '#27569C',
					name: 'views',
					type: 'area',
					dataPoints: [],
				}],
			},
		},
		];
	}
}
