import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
		data: { preload: true },
	},
	{
		path: 'contract',
		loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule),
		data: { preload: true },
	},
	{
		path: 'customer',
		loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
	},
	{
		path: 'security',
		loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
	},
	{
		path: 'cPanel',
		loadChildren: () => import('./cpanel/cpanel.module').then(m => m.CpanelModule),
		data: { preload: true },
	},
	{
		path: 'agent',
		loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule),
		data: { preload: true },
	},
	{
		path: 'auction',
		loadChildren: () => import('./auction/auction.module').then(m => m.AuctionModule),
	},
	{
		path: 'rates',
		loadChildren: () => import('./rates/rates.module').then(m => m.RatesModule),
	},
	{
		path: 'chart',
		redirectTo: 'market-analytics',
	},
	{
		path: 'market-analytics',
		loadChildren: () => import('./market-analytics/market-analytics.module').then(m => m.MarketAnalyticsModule),
	},
	{
		path: 'faq',
		loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
	},
	{
		path: 'education',
		loadChildren: () => import('./education/education.module').then(m => m.EducationModule),
	},
	{
		path: 'market-overview',
		loadChildren: () => import('./market-overview/market-overview.module').then(m => m.MarketOverviewModule),
	},
	{
		path: 'task',
		loadChildren: () => import('./task/task.module').then(m => m.TaskModule),
	},
	{
		path: 'search',
		loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
	},
	{
		path: 'attachment',
		loadChildren: () => import('./attachment/attachment.module').then(m => m.AttachmentModule),
	},
	{
		path: 'ticket',
		loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule),
	},
	{
		path: 'report',
		loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
		data: { preload: true },
	},
	{
		path: 'supplier',
		loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule),
		data: { preload: true },
	},
	{
		path: 'direct',
		loadChildren: () => import('./direct/direct.module').then(m => m.DirectModule),
	},
	{
		path: 'esign',
		loadChildren: () => import('./esign/esign.module').then(m => m.EsignModule),
	},
	{
		path: 'short-urls',
		loadChildren: () => import('./short-url/short-url.module').then(m => m.ShortUrlModule),
	},
	{
		path: 'short-url-landing',
		loadChildren: () => import('./short-url-landing/short-url-landing.module').then(m => m.ShortUrlLandingModule),
	},
	{
		path: 'rate-matrix-loader-dashboard',
		loadChildren: () => import('./rate-matrix-loader-dashboard/rate-matrix-loader-dashboard.module')
			.then(m => m.RateMatrixLoaderDashboardModule),
	},
	{
		path: 'error',
		loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
	},
	{
		path: 'news',
		loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
	},
	{
		path: 'support-center',
		loadChildren: () => import('./support-center/support-center.module').then(m => m.SupportCenterModule),
	},
	{
		path: 'market-update',
		loadChildren: () => import('./market-update/market-update.module').then(m => m.MarketUpdateModule),
	},
	{
		path: 'document-templates',
		loadChildren: () => import('./document-templates/document-templates.module').then(m => m.DocumentTemplatesModule),
	},
	{
		path: 'feedback',
		loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
	},
	{
		path: 'nps-csat-feedback',
		loadChildren: () => import('./nps-csat-feedback/nps-csat-feedback.module').then(m => m.NpsCsatFeedbackModule),
	},
	{
		path: 'supplier-payment-report',
		loadChildren: () => import('./supplier-payment-report/supplier-payment-report.module').then(m => m.SupplierPaymentReportModule),
	},
	{
		path: 'admin',
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
	},
	{
		path: 'lead-link',
		loadChildren: () => import('./ecl/ecl.module').then(m => m.EclModule),
	},
	{
		path: 'customer-engagement',
		loadChildren: () => import('./customer-engagement/customer-engagement.module').then(m => m.CustomerEngagementModule),
	},
	{
		path: 'agent-engagement',
		loadChildren: () => import('./agent-engagement/agent-engagement.module').then(m => m.AgentEngagementModule),
	},
	{
		path: 'referral-exchange',
		loadChildren: () => import('./referral-exchange/referral-exchange.module').then(m => m.ReferralExchangeModule),
	},
	{
		path: 'lead',
		loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule),
		data: { preload: true },
	},
	{
		path: 'layered-purchasing',
		loadChildren: () => import('./layered-purchasing/layered-purchasing.module').then(m => m.LayeredPurchasingModule),
	},
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	{
		path: '**',
		redirectTo: 'dashboard',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [
		RouterModule,
	],
})
export class AppRoutingModule { }
