import { DeliveryType } from './delivery-type';
import { SupplierCommissionMapCommissionType } from './supplier-commission-map-commission-type';
import { SupplierCommissionMapState } from './supplier-commission-map-state';

export class SupplierCommissionMap {

	public id: number;
	public supplierId: string;
	public brandSupplierId: string;
	public description: string;
	public sheetName: string;
	public headerType: string;
	public headerRow: number;
	public brandName: string;
	public brandNameCol: string;
	public customerFNameCol: string;
	public customerLNameCol: string;
	public accountNumberCol: string;
	public meterStartDateCol: string;
	public meterEndDateCol: string;
	public usageCol: string;
	public marginCol: string;
	public supplierPaymentCol: string;
	public commodityCol: string;
	public descriptionCol: string;
	public commissionTypeCol: string;
	public commissionTypeCol2: string;
	public commissionTypeCol3: string;
	public defaultCommodity: string;
	public defaultDescription: string;
	public defaultCommissionTypeName: string;
	public usageFactor: number;
	public marginFactor: number;
	public validationCol: string;
	public hasCommercialCommission: boolean;
	public hasResidentialCommission: boolean;
	public externalIdCol: string;
	public deliveryTypeId: number;

	public supplierCommissionMapStates: SupplierCommissionMapState[];
	public supplierCommissionMapCommissionTypes: SupplierCommissionMapCommissionType[];
	public deliveryType: DeliveryType;

	constructor(supplierCommissionMap?: SupplierCommissionMap) {
		Object.assign(this, supplierCommissionMap);

		this.supplierCommissionMapStates = this.supplierCommissionMapStates
			? this.supplierCommissionMapStates.map(b => new SupplierCommissionMapState(b))
			: this.supplierCommissionMapStates;
		this.supplierCommissionMapCommissionTypes = this.supplierCommissionMapCommissionTypes
			? this.supplierCommissionMapCommissionTypes.map(b => new SupplierCommissionMapCommissionType(b))
			: this.supplierCommissionMapCommissionTypes;

		if (this.deliveryType) {
			this.deliveryType = new DeliveryType(this.deliveryType);
		}
	}
}
