/* eslint-disable @typescript-eslint/naming-convention */
export const environmentShared = {
	timezone: 'America/Chicago',
	dateFormat: 'MMM dd, yyyy',
	dateFormatShort: 'MMM, yyyy',
	dateFormatFriendly: 'MM/dd/yyyy',
	dateTimeFormat: 'MMM dd, yyyy hh:mm a',
	powerKioskSupportTeamName: 'Power Kiosk Support Team',
	security: {
		signIn: '/security/signIn',
		forgotLogin: '/security/forgotLogin',
		forgotPassword: '/security/forgotPassword',
	},
	contracts: {
		dashboard: {
			stats: '/contracts/dashboard/stats',
		},
		resendEnrollmentEmail: '/contract/resendEnrollmentEmail/contractId/',
		updateSolr: '/contract/updateSolr/contractId/',
		home: '/contract',
		doSignedContractEmail: '/contract/doSignedContractEmail',
		createCustomerLogin: '/contract/createCustomerLogin',
		generateLOA: '/contract/generateLOA/contractId/',
		generateLOE: '/contract/generateLOE/contractId/',
		packages: {
			edit: '/contractPackages/edit/',
			remove: '/contractPackages/remove/',
		},
	},
	auction: {
		sessionInfo: '/bidding-session/sessionInfo/contractId/',
		index: '/bidding-session',
	},
	renewal: '/renewal/contractId/',
	commissionAdjustment: {
		edit: '/commission-adjustment/edit/',
		index: '/commission-adjustment',
	},
	commission: {
		pending: '/commissions/pendingCommissions/',
	},
	additionalInsureds: {
		edit: '/additionalInsureds/edit/',
		remove: '/additionalInsureds/remove/',
	},
	pets: {
		edit: '/pets/edit/',
		remove: '/pets/remove/',
	},
	lossHistories: {
		edit: '/lossHistories/edit/',
		remove: '/lossHistories/remove/',
	},
	customerEngagement: {
		list: '/customer-engagement/emails',
	},
	agentEngagement: {
		list: '/agent-engagement/emails',
	},
	cPanel: {
		home: '/cPanel/home',
		optOutReasons: {
			add: '/cPanel/opt-out-reasons/add',
			list: '/cPanel/opt-out-reasons/list',
			edit: '/cPanel/opt-out-reasons/edit',
		},
		agent: {
			list: '/cPanel/agents/list',
			edit: '/cPanel/agents/edit',
			add: '/cPanel/agents/add',
		},
		isos: {
			list: '/cPanel/isos/list',
			edit: '/cPanel/isos/edit',
		},
		products: {
			list: '/cPanel/products/list',
			edit: '/cPanel/products/edit',
		},
		companies: {
			list: '/cPanel/companies/list',
			edit: '/cPanel/companies/edit',
		},
		licensePlans: {
			list: '/cPanel/license-plans/list',
			edit: '/cPanel/license-plans/edit',
			add: '/cPanel/license-plans/add',
		},
		menuTypes: {
			list: '/cPanel/menu-types/list',
			edit: '/cPanel/menu-types/edit',
			add: '/cPanel/menu-types/add',
		},
		marginChangeReasonTypes: {
			list: '/cPanel/margin-change-reason-types/list',
			edit: '/cPanel/margin-change-reason-types/edit',
			add: '/cPanel/margin-change-reason-types/add',
		},
		permissions: {
			list: '/cPanel/permissions/list',
			edit: '/cPanel/permissions/edit',
			add: '/cPanel/permissions/add',
		},
		reportTypes: {
			list: '/cPanel/report-types/list',
			edit: '/cPanel/report-types/edit',
			add: '/cPanel/report-types/add',
		},
		priceElements: {
			list: '/cPanel/price-elements/list',
			edit: '/cPanel/price-elements/edit',
			add: '/cPanel/price-elements/add',
		},
		report: {
			list: '/cPanel/reports/list',
			edit: '/cPanel/reports/edit',
			add: '/cPanel/reports/add',
		},
		settings: {
			list: '/cPanel/settings/list',
			edit: '/cPanel/settings/edit',
			add: '/cPanel/settings/add',
		},
		extendAuctionReasons: {
			list: '/cPanel/extend-auction-reasons/list',
			edit: '/cPanel/extend-auction-reasons/edit',
			new: '/cPanel/extend-auction-reasons/add',
		},
		states: {
			list: '/cPanel/states/list',
			edit: '/cPanel/states/edit',
		},
		customer: {
			edit: '/cPanel/customers/edit',
		},
		agents: {
			list: '/cPanel/agents/list',
			edit: '/cPanel/agents/edit',
			add: '/cPanel/agents/add',
		},
		users: {
			list: '/cPanel/users/list',
			edit: '/cPanel/users/edit',
			add: '/cPanel/users/add',
			show: '/cPanel/users/show',
		},
		alertTypes: {
			list: '/cPanel/alert-types/list',
			edit: '/cPanel/alert-types/edit',
		},
		attachmentTypes: {
			list: '/cPanel/attachment-types/list',
			add: '/cPanel/attachment-types/add',
			edit: '/cPanel/attachment-types/edit',
		},
		serviceTypes: {
			list: '/cPanel/service-types/list',
			edit: '/cPanel/service-types/edit',
		},
		utilities: {
			list: '/cPanel/utilities/list',
			edit: '/cPanel/utilities/edit',
		},
		suppliers: {
			list: '/cPanel/suppliers/list',
			edit: '/cPanel/suppliers/edit',
		},
		emailCriteria: {
			list: '/cPanel/email-criteria/list',
			edit: '/cPanel/email-criteria/edit',
		},
		emailAccount: {
			list: '/cPanel/email-account/list',
			edit: '/cPanel/email-account/edit',
		},
		ticketCategories: {
			list: '/cPanel/ticket-categories/list',
			edit: '/cPanel/ticket-categories/edit',
		},
		menu: {
			list: 'cPanel/menus/list',
			edit: 'cPanel/menus/edit',
		},
		noteType: {
			list: '/cPanel/note-types/list',
			edit: '/cPanel/note-types/edit',
		},
		document: {
			list: '/cPanel/documents/list',
			edit: '/cPanel/documents/edit',
		},
		faq: {
			list: '/cPanel/faqs/list',
			edit: '/cPanel/faqs/edit',
		},
		education: {
			list: '/cPanel/educations/list',
			edit: '/cPanel/educations/edit',
		},
		faqCategory: {
			list: '/cPanel/faq-categories/list',
			edit: '/cPanel/faq-categories/edit',
		},
		educationCategory: {
			list: '/cPanel/education-categories/list',
			edit: '/cPanel/education-categories/edit',
		},
		educationType: {
			list: '/cPanel/education-types/list',
			edit: '/cPanel/education-types/edit',
		},
		app: {
			list: '/cPanel/apps/list',
			edit: '/cPanel/apps/edit',
		},
		role: {
			list: '/cPanel/roles/list',
			edit: '/cPanel/roles/edit',
		},
		securityRule: {
			list: '/cPanel/security-rules/list',
			edit: '/cPanel/security-rules/edit',
		},
		agentDiscountPrograms: {
			list: '/cPanel/agent-discount-programs/list',
			edit: '/cPanel/agent-discount-programs/edit',
		},
		holiday: {
			list: '/cPanel/holidays/list',
			edit: '/cPanel/holidays/edit',
		},
		jobs: {
			list: '/cPanel/jobs/list',
			edit: '/cPanel/jobs/edit',
		},
		deliveryTypes: {
			list: '/cPanel/delivery-types/list',
			edit: '/cPanel/delivery-types/edit',
		},
		emailCriteriaTypes: {
			list: '/cPanel/email-criteria-types/list',
			edit: '/cPanel/email-criteria-types/edit',
		},
		commissionPayPlans: {
			list: '/cPanel/commission-pay-plans/list',
			edit: '/cPanel/commission-pay-plans/edit',
		},
	},
	task: {
		home: '/task',
		add: '/task/add',
	},
	attachments: {
		delete: '/attachment/delete/attachmentId/',
		show: '/attachment/show/attachmentId/',
		edit: '/attachment/edit/',
	},
	help: {
		faq: '/help/faq',
	},
	exportImport: {
		home: '/exportImport/home',
		pendingCommissions: '/exportImport/Commissions',
		residentialSupplierRates: '/exportImport/residentialSupplierRates',
	},
	chart: {
		home: '/chart',
	},
	education: {
		home: '/education',
	},
	marketing: {
		home: '/marketing',
	},
	report: {
		home: '/report',
		agent: '/agent',
		commissionpaid: '/commissionpaid',
	},
	user: {
		impersonate: '/security/impersonateUser/',
		edit: '/cPanel/users/edit/',
		show: '/cPanel/users/show/',
	},
	suppliers: {
		info: '/supplier/info/',
	},
	direct: {
		begin: '/direct/begin/',
		payment: {
			new: '/direct/payment/new/',
			confirmation: '/direct/payment/confirmation/',
		},
		customer: {
			quote: '/direct/customer/quote/',
		},
	},
	apiUrls: {
		'https://oe-portal.com': 'https://api.oe-portal.com',
	},
	apiKeys: { // put alphabetically to make it easy to find
		'http://localhost:4200': 'e6b0adf418e0498f93b0bb5de0e56b2d',
		'https://brokertest.powerkiosk.com': 'd2363043b2f9448bbd70acbb2007c04b',
		'https://suppliertest.powerkiosk.com': '3dc3dc076b154611b8eb9ab2f85f344d',
		'https://brokerstaging.powerkiosk.com': 'cd0d585c6a9644a2997deb9ad90b0be5',
		'https://supplierstaging.powerkiosk.com': '144a039e657d4c869e559fbfb85c6608',
		'https://brokerdemo.powerkiosk.com': 'ec7e27c685ee4caa87e58ab6e93d7d55',
		'https://broker.powerkiosk.com': 'cd0d585c6a9644a2997deb9ad90b0be5',
		'https://brokersupport.powerkiosk.com': 'de2b9e08d9924589b95594114193b2f0',
		'https://supplier.powerkiosk.com': '144a039e657d4c869e559fbfb85c6608',
		'https://saastest.powerkiosk.com': '4b3d789d9b684adeb8072d205cf3602c',
		'https://broker.onepowersolutions.com': 'e4a2eafbdc33427ea2efce768491965a',
		'https://broker-oneenergy.onepowersolutions.com': 'fd87cfc21acc46b89d7fe3466f044a5f',
		'https://broker-onemgmt.onepowersolutions.com': 'bd043c2d97114d248fa4675d665eadb6',
		'https://centabode.com': '3e00c86020fd4f26a57783ecb4c23c46',
		'https://confirm.financeguru.com': '0df4abe9dea447ebabf02024b4e9de4b',
		'https://customer.energyconsultingsolutions.com': '61a8db23fff14892917cd42942b0b588',
		'https://demcoportal.com': 'd02ddfe15d8040139cf58bd4541b0c82',
		'https://discountenergyrate.buzz': '7be6d5f6754549d0bff64c586975dfe8',
		'https://e2powerback.com': '0fbfd829c7b44e19bb1ae51dfb829469',
		'https://energy.overviewconsulting.com': 'db8b5a3720554aab931f10238cd88740',
		'https://forceenergyapp.com': '81295c169e8e4b6e8a4bcdbb0b522641',
		'https://home.cbsworks.net': '2cfe3d18f7d84b9f832505c06601c9fb',
		'https://keystonepowerkiosk.com': 'be216698dbe049c791978aef6512112e',
		'https://peak-portal.com': '720bd9caaa10440d876864f911be1b3f',
		'https://portal.rosenthalenergy.com': 'f726b88d47194ff09745955c927e2ab3',
		'https://portal.utilibid.com': 'ffbbbb96b4064a21a4df1bbcc555aaff',
		'https://powerofchoosing.info': '66e96bba791244ae88b438b7be250a7e',
		'https://portal.pwrshp.com': '641ace6d395746e39817f80abe5921e0',
		'https://rate.bidonenergy.org': '988618210f514dc18593194aad2c6051',
		'https://truesynergybroker.com': '1ce42051c36b4b5e80440f1d7c424b1e',
		'https://trupowurbackoffice.com': 'fb7b9c1d22b04b55b009f2922a27b72e',
		'https://usintegrityenergy.net': 'b74073e554ae45dba2c41dd6609cc3f2',
		'https://verification.saveonmyutility.com': '313d70fcbfe54e7bb53a7cc321a9ecb0',
		'https://rate.youtilities.solutions': '7bd6e80438124981a133caeb7950c635',
		'https://currentnrgorders.com': '1ee6100434504a29b3f47c52364088a6',
		'https://portal.choiceenergy.network': 'd9b0e5cae3b74b559b0873b295070b6a',
		'https://portal.choiceenergynetwork.com': 'd9b0e5cae3b74b559b0873b295070b6a',
		'https://powertochoose.enficiency.com': '83a32fd5089f49b4beb582fbd97977ff',
		'https://broker.networkenergyxchange.com': '8897acb18af84ebf8934840fe2556af8',
		'https://portal.akspertsventures.com': '32b30b5192d14a3ea93e36f7923c926c',
		'https://eacora.com': '273bcccd788e415db43183e126dece34',
		'https://portal.faton.biz': '40f26af0a1cf420b977b2ec2df46839a',
		'https://portal.energysalesteam.com': 'ea8ce897bd67479aac7269efe7870ca3',
		'https://portal.nrgrates.com': 'cef645df32d24ab5925e816a371dc30c',
		'https://energybrokersonline.us': 'f90c4c713e8b4360a749f07ec57f7623',
		'https://energyrates-fintesa.com': '1c861c59bd2045bba9d66ac2569a6513',
		'https://conxionenergy.solutions': '6f2a927900224e3baf4daa0d06f57c1d',
		'https://oe-portal.com': '399ee00d293f4cee81d38a12410593c7',
		'https://energychoiceadjust.com': '81dd1d080bcf461ab77b83ebd31acb2e',
		'https://backdemo.trupowuracademy.com': '9a5570d2a9571c55083e0bccc8c92b8d',
		'https://portal.businessenergy.broker': 'a41e14fb7a574e54bb65c622d632a437',
		'https://broker.unitedpowerandgas.com': '8a58054b740b4b6fa42d21c7b70548ab',
		'https://broker.finessemarketinginc.com': 'e5e442e8100b48098ecab2767651d848',
		'https://247energyoutlet.com': '5f1e2d6825414d68b5435cda2dcc6e2a',
		'https://oneupnetwork.com': '92a06960bc36402d9da805d888ee27d6',
		'https://revenue-source-group.powerkiosk.com': '1b31846748794f79965079e193254a93',
		'https://broker.terribite.com': 'b4f75f0cad134b518215cfe85d10ea15',
		'https://ueacrm.ueallc.com': '0ac98a2f80d54624ae745bad0765d971',
		'https://broker.savantenergy.co': 'be7e939146844b139be8aa9b883b0d6f',
		'https://broker.dishaenergy.com': 'de2cfa74793044fea1bd254d5f0c01b7',
		'https://broker.streamfienergy.com': '24428923c0cc4a52912b7a84578c1991',
		'https://salesbigenergysavings.org': '3898ca4fb42348968e8683ef5e8dff4b',
		'https://qualitylightingassociates.com': '04067bad48d9438785bf84704b89de05',
		'https://intel.ucepartners.com': 'df03bf083b37434fbe1ba0a034293ad4',
		'https://energy.vertexfullstack.com': '7a3a22a55e4540b8bb2a7ab1266a933b',
		'https://broker.smartrate.us': '09b1aa95dae846c3a5bf5f0a3096eaab',
		'https://snbenergy.com': '88e65e7aa872483f85259a65635cb4b9',
		'https://snbgroup.us': '88e65e7aa872483f85259a65635cb4b9',
		'https://rootbroker.com': 'c72cf93195d44811ad8bfe36dd65e56f',
		'https://contracts.briarcreekenergy.com': '055c9af68c4f41279fc35c9b1b046a4f',
		'https://broker.zingoenergy.com': '523b559e47894de4a1278cd3c1f5830a',
	},
};
