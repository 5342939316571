import { CommissionPayPlanType } from './commission-pay-plan-type';

export class CommissionOption {
	public commissionPayPlanTypeId: number;
	public percentage: number;
	public estTotalCommission: number;
	public estAnnualPayment: number;
	public estFirstPayment: number;
	public estFirstPaymentDescription: string;

	public commissionPayPlanType: CommissionPayPlanType;
	public isAdminOnly: boolean;
}
