import { Pipe, PipeTransform } from '@angular/core';
import { RfqSessionBidUtils } from '@pk/powerkioskutils';

import { HelperService } from '../helper.service';
import { RfqSessionBid, RfqSessionStat } from '../models';

@Pipe({
	name: 'convertBidUnits',
})
export class ConvertBidUnitsPipe implements PipeTransform {

	constructor(private helperService: HelperService) { }

	transform(bid: number | RfqSessionBid | RfqSessionStat, serviceTypeId: string, units: string): unknown {
		const amount = bid instanceof RfqSessionBid // bid
			? bid.rate
			: bid instanceof RfqSessionStat // stat
				? bid.bestBid
				: bid; // number
		return RfqSessionBidUtils.convertBidToInternalUnits(amount, serviceTypeId, units, true);
	}

}
