/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import { NumberUtils } from '@pk/powerkioskutils';

import * as moment from 'moment-timezone';

import { Attachment } from './attachment';
import { CommissionType } from './commission-type';
import { Contract } from './contract';
import { ContractLocation } from './contract-location';
import { PendingCommissionIssue } from './pending-commission-issue';

export enum ProcessTimeType {
	EOD = 'EOD',
	EOM = 'EOM'
}

export class PendingCommission {

	public id: number;
	public modifiedDate: string;
	public contractId: string;
	public contractLocationId: string;
	public supplierId: string;
	public serviceTypeId: string;
	public customerName: string;
	public accountNumber: string;
	public meterStartDate: Date | string;
	public meterEndDate: Date | string;
	public usage: number | string;
	public margin: number | string;
	public supplierPayment: number | string;
	public supplierName: string;
	public commodity: string;
	public description: string;
	public isDirectSale: boolean;
	public isProcessed: boolean;
	public isArchived: boolean;
	public warnings: string;
	public errors: string;
	public addDate: string | Date;
	public status: string;
	public commissionTypeId: number;
	public rate: number;
	public attachmentId: string;
	public reversePendingCommissionId: string;
	public isGuaranteePayment: boolean;
	public date: string | Date;
	public groupId: number;
	public supplierCommissionMapId: number;
	public reportDate: string | Date;
	public externalId: string;
	public externalCommissionId: string;
	public exchangeRate: number;

	public contract: Contract;
	public contractLocation: ContractLocation;
	public commissionType: CommissionType;
	public attachment: Attachment;
	public pendingCommissionIssues: PendingCommissionIssue[];

	// internal use (remove, replaced by pending commission display)
	public selectedForProcess: boolean;
	public issues: string;
	public processTimeType: ProcessTimeType;
	public processDate: string | Date;

	constructor(pendingCommission?: PendingCommission) {
		Object.assign(this, pendingCommission);

		this.meterStartDate = this.meterStartDate
			? new Date(moment(this.meterStartDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterStartDate;
		this.meterEndDate = this.meterEndDate
			? new Date(moment(this.meterEndDate).format('MMM DD, YYYY 00:00:00 a')) : this.meterEndDate;
		this.addDate = this.addDate && !moment.isDate(this.addDate)
			? new Date(moment(this.addDate).format('MMM DD, YYYY 00:00:00 a')) : this.addDate;
		this.reportDate = this.reportDate && !moment.isDate(this.reportDate)
			? new Date(moment(this.reportDate).format('MMM DD, YYYY 00:00:00 a')) : this.reportDate;
		this.contract = this.contract ? new Contract(this.contract) : this.contract;
		this.contractLocation = this.contractLocation ? new ContractLocation(this.contractLocation) : this.contractLocation;
		this.commissionType = this.commissionType ? new CommissionType(this.commissionType) : this.commissionType;
		this.attachment = this.attachment ? new Attachment(this.attachment) : this.attachment;
		this.date = this.date && !moment.isDate(this.date)
			? new Date(moment(this.date).format('MMM DD, YYYY 00:00:00 a')) : this.date;
	}

	public getDisplayUsage(unit?: string): string {
		const usage = NumberUtils.amountToUnits(this.usage, unit);

		return Math.round(usage).toString();
	}

	public getDisplayMargin(unit?: string): string {
		const margin = NumberUtils.rateToUnits(Number(this.margin), unit);

		return margin.toString();
	}

	get meterReadTerm(): string {
		if (this.meterStartDate && this.meterEndDate) {
			return moment(this.meterEndDate).diff(this.meterStartDate, 'day').toString();
		}

		return '-';
	}
}
