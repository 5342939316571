export class LendingModuleSettingQuickPayMarginThresholds {

	public id: number;
	public minDropRatio: number;
	public maxDropRatio: number;
	public maxAllowedMargin: number;

	constructor(lendingModuleSettingQuickPayMarginThresholds: LendingModuleSettingQuickPayMarginThresholds) {
		Object.assign(this, lendingModuleSettingQuickPayMarginThresholds);
	}
}
