import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';

import * as _ from 'lodash';
import moment from 'moment-timezone';
import { BsModalService } from 'ngx-bootstrap/modal';

import { FaqSearchModalComponent } from 'src/app/faq/modals/faq-search-modal/faq-search-modal.component';
import { SecurityService } from 'src/app/security/security.service';
import { GoogleTagManagerService } from 'src/app/shared/google/google-tag-manager.service';
import { AgentDiscountProgram, User } from '../../../shared/models';

@Component({
	selector: 'pk-broker-navbar-financial-profile',
	templateUrl: './navbar-financial-profile.component.html',
	styleUrls: ['./navbar-financial-profile.component.scss'],
})
export class NavbarFinancialProfileComponent implements OnInit {

	@Output() toggledFinancialProfileProgram: EventEmitter<boolean> = new EventEmitter();
	@Input() user: User;
	@Input() agentDiscountPrograms: AgentDiscountProgram[];

	public carouselActiveSlideId = 0;
	public loadingCommission = true;
	public showFrequentBroker = false;

	constructor(
		private currencyPipe: CurrencyPipe,
		private titleService: Title,
		private modalService: BsModalService,
		private securityService: SecurityService,
		private googleTagManagerService: GoogleTagManagerService
	) { }

	ngOnInit(): void {
		this.showFrequentBroker = this.user.agent.isBusinessFull && !this.isSaas && (this.user.agent.parent
			? this.user.agent.useAgentDiscountProgram && this.user.agent.parent.useAgentDiscountProgram
			: this.user.agent.useAgentDiscountProgram);
	}

	get totalRemainingText(): string {
		if (this.nextAgentDiscountProgram) {
			return `You need in total ${this.currencyPipe.transform(this.volumeToNextDiscountProgram, 'USD', 'symbol', '1.0-0')} ` +
				`Remaining Term Value to reach ${this.nextAgentDiscountProgram.name} level. ` +
				`Remaining Term Value = (Remaining Months from Today to Contract Expiration Date) / 12 * Annual Usage * Margin`;
		}

		return '';
	}

	get currentLevelStyle(): string { return this.getLevelStyle(this.user.agent.discountProgram); }
	get nextLevelStyle(): string { return this.getLevelStyle(this.nextAgentDiscountProgram); }
	get isDecoupled(): boolean { return this.user.company.isDecoupled; }
	get isSaas(): boolean { return this.user.isSaas; }
	get isImpersonating() { return this.securityService.isImpersonating; }
	get loggedInUser() { return this.securityService.authFields?.loggedInUser; }
	get agentDropRatio() {
		return _.max([this.user.agent?.dropRatio || 0, this.user.agent?.parent?.dropRatio || 0]);
	}

	private getLevelStyle(discountProgram: AgentDiscountProgram, index?: number): string {
		if (!discountProgram) { return ''; }

		let classes = '';

		switch (discountProgram.id) {
			case 3: {
				classes += 'full-color-style';
				break;
			}
			case 4: {
				classes += 'silver-style';
				break;
			}
			case 5: {
				classes += 'gold-style';
				break;
			}
			case 6: {
				classes += 'platinum-style';
				break;
			}
			case 7: {
				classes += 'diamond-style';
				break;
			}
			default: {
				classes += 'full-color-style';
				break;
			}
		}

		if (index !== null && index !== undefined) {
			switch (index) {
				case 0: {
					classes += ' pl-3 pr-0';
					break;
				}
				case this.agentDiscountPrograms.length - 1: {
					classes += ' px-0';
					break;
				}
				default: {
					classes += ' pl-0';
					break;
				}
			}
		}

		return classes;
	}

	public getMaxVolume(discountProgram: AgentDiscountProgram): string {
		if (!discountProgram) { return ''; }

		if (discountProgram.maxVolume <= 999999) {
			const shortMaxVolume = discountProgram.maxVolume / 1000;
			return `$${shortMaxVolume}K`;
		}
		if (discountProgram.maxVolume > 999999) {
			const shortMaxVolume = discountProgram.maxVolume / 1000000;
			return `$${shortMaxVolume}M`;
		}
	}

	get nextAgentDiscountProgram(): AgentDiscountProgram {
		if (this.agentDiscountPrograms) {
			for (const program of this.agentDiscountPrograms) {
				if (program.minVolume > this.user.agent.volume) {
					return program;
				}
			}
		}

		return null;
	}

	get volumeToNextDiscountProgram(): number {
		if (this.nextAgentDiscountProgram) {
			return this.nextAgentDiscountProgram.minVolume - this.user.agent.volume;
		}

		return 0;
	}

	get lastMonthDisplay(): string {
		return moment().subtract(1, 'month').format('MMM YYYY');
	}

	get currMonthDisplay(): string {
		return moment().format('MMM YYYY');
	}

	get reversibleCommission(): number {
		return this.user.agent.reversibleCommission || 0;
	}

	get estimatedFutureCommission(): number {
		return Math.max(this.user.agent.futureCommissionAmount + this.user.agent.negativeBalance, 0);
	}

	get estimatedCommissionCurrMonth(): number {
		return this.user.agent.currMonthEstimatedCommission;
	}

	get commissionPaidLastMonth(): number {
		return this.user.agent.lastMonthPaidCommission;
	}

	get negativeBalance(): number {
		return this.user.agent.negativeBalance;
	}

	get flowedTermValue(): number {
		return this.user.agent.flowedTermValue;
	}

	get maxReversibleCommission(): number {
		return this.user.agent.maxReversibleCommission;
	}

	get maxReversibleCommissionOverride(): number {
		return this.user.agent.maxReversibleCommissionOverride;
	}

	public toggleFinancialProfileProgram(): void {
		this.toggledFinancialProfileProgram.emit(true); // collapse
	}

	public sendPageEvent(action: string): void {
		this.googleTagManagerService.sendPageEvent(action, this.user, this.titleService.getTitle());
	}

	public openFaqSearch(): void {
		this.modalService.show(FaqSearchModalComponent, {
			class: 'pk-modal modal-dialog-slideout',
			ignoreBackdropClick: true,
			initialState: {},
		});
	}
}
