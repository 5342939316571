export class SupplierMarketMatrixPriceAdjustmentSummaryReportDetail {
	public date: string;
	public increaseInMarginMoreThan5MarginNumCases: number;
	public increaseInMarginMoreThan5MarginImpactSize?: number;
	public increaseInMarginUpTo5MarginNumCases: number;
	public increaseInMarginUpTo5MarginImpactSize?: number;
	public decreaseInMarginUpTo5MarginNumCases: number;
	public decreaseInMarginUpTo5MarginImpactSize?: number;
	public decreaseInMarginMoreThan5MarginNumCases: number;
	public decreaseInMarginMoreThan5MarginImpactSize?: number;
}

export class SupplierMarketMatrixPriceAdjustmentSummaryReport {
	public numberOfCases: SupplierMarketMatrixPriceAdjustmentSummaryReportDetail;
	public dates: SupplierMarketMatrixPriceAdjustmentSummaryReportDetail[];
}
