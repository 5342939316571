import * as moment from 'moment-timezone';

export class CommissionPayPlan {

	public id: number;
	public name: string;
	public description: string;
	public upfrontPercentage: number;
	public upfrontLag: number;
	public monthlyLag: number;
	public upfrontDateType: number;
	public hasAnniversaryUpfront: boolean;
	public anniversaryLag: number;
	public contractStartDate: Date;
	public contractEndDate: Date;
	public discountFactor: number;
	public sortOrder: number;
	public isActive: boolean;
	public commissionPayPlanTypeId: number;
	public interval: string;

	constructor(commissionPayPlan?: Subset<CommissionPayPlan>) {
		Object.assign(this, commissionPayPlan);

		this.contractStartDate = this.contractStartDate
			? new Date(moment(this.contractStartDate).format('MMM DD, YYYY hh:mm:ss a')) : this.contractStartDate;
		this.contractEndDate = this.contractEndDate
			? new Date(moment(this.contractEndDate).format('MMM DD, YYYY hh:mm:ss a')) : this.contractEndDate;
	}

	get isNoExpiration(): boolean {
		return moment(this.contractEndDate).isSameOrAfter(moment('2100-01-01T00:00:00'), 'day');
	}
}
